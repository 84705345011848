@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home-container{
    width: 100%;
}
.home-container img{
    height: 50vh;
    
}
.home-info, .home-success,.home-services {
   padding: 5%;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
}
.home-info img{
    width: 200px;
    height: 400px;
    object-fit: cover;
}
.info-child,.service-child {
    display: inline-block;
    padding: 1%;
    /* font-size: 34px; */
}
.info-child:first-child, .service-child:first-child{
    width: 100%;
}
.info-child:not(:first-child),.service-child:not(:first-child){
    flex: 1 1;
    /* font-size: 24px; */
}
.success-child{
    text-align: center;
    width: 25%;
}
.success-child img{
    height: 100px;
}
.service-child{    
    position: relative;
}
.service-child h3{
    text-align: center;
}
.service-child img{
    opacity: 1;
    width: 100%;
    height: auto;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}
.service-middle{
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top:35%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    background-color: #f79f34;
}
.service-child:hover img{
    opacity: 0.3;
}
.service-child:hover .service-middle{
    opacity: .9;
    
}
.text{
    background-color: #f79f34;
    color: black;
    font-size: 18px;
    padding: 16px 32px;
}
@media only screen and (max-width: 1740px) {
     .service-child:not(:first-child){
         flex: auto;
     }
}
@media only screen and (max-width: 869px) {
    .home-info, .home-success {
        flex-direction: column;
        align-items: center;
    }
    .home-info img{
        width: 100%;
        height: 200px;
    }
    /* .service-child{
        width: 100%;
        
    }
    .service-child img{
        width: 100px;
        height: auto;
        display: block;
        position: relative;
        margin: 0 auto;
        
    } */
    .success-child {
        width: 100%;
    }
    .text{
        font-size: 12px;
    }
}
.IconContainer:hover .renovIcon{
    -webkit-animation: fa-spin .3s forwards linear;
            animation: fa-spin .3s forwards linear;
    
    cursor: pointer;
    
}
@-webkit-keyframes fa-spin{
    0%{
        -webkit-transform:rotate(0deg);
        transform:rotate(0deg)}
        100%{
            -webkit-transform:rotate(30deg);
            transform:rotate(30deg)
        }
    }
@keyframes fa-spin{
    0%{
        -webkit-transform:rotate(0deg);
        transform:rotate(0deg)}
        100%{
            -webkit-transform:rotate(30deg);
            transform:rotate(30deg)
        }
    }
.IconContainer{
    text-align: center;
}
.listGroup{
    min-height: 20em;
}
 .bg-bronze{

    background:linear-gradient(90deg, #804a00, #f6b068, #ba7b36, #d7954f )!important;
}
.bg-silver{

    background:linear-gradient(90deg, #a2a2a2, #fffff1, #c0c0c0, #d7d7d7d8 )!important;

} 
.bg-gold{
    background:linear-gradient(90deg, #AE8625, #F7EF8A, #D2AC47, #EDC967 )!important;

}

.btn-gradient{
    color: azure!important;
}
/* #container{
    width: 100%;
    margin: 0 auto;
} */
/* Partners*/
#partner-banner{
    margin: 0 auto;
    /* background:linear-gradient(90deg, rgb(0, 174, 239) 0%, rgb(0, 174, 239) 100%);  */
    background-color: rgb(9, 82, 151);
    padding: 2%;
    text-align: center;
}
#partner-banner h2{
    text-align: left;
    color: #fff;
    
}
.partner-images{
    display: flex; 
    flex-wrap: wrap; 
    justify-content: space-around; 

    
}
.partner-images img{
height: 70px;
    width: 8%;
margin-left: 65px;
}


/* Footer */
footer{
    padding: 5%;
    display: flex;
    justify-content:center;
    align-items: center;
    flex-wrap: wrap;
}
.footer-item{
    width: 33%;
    padding-left:4% ;
}
footer ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
footer ul span{
    color: rgb(179, 57, 57);
}
.second {
    width: 12%;
}
form{
    border: #302f2f 1px solid;
    padding: 5%;
    box-shadow: #363636 3px 5px;
    
}
form input, form textarea{
	width:100%;
	margin-bottom:5%;
}
form input[type=submit]{
    box-shadow: #363636 5px 5px;
	background-color:#34C759;
	color:white;
	border-radius:5px;
	padding: 10px;
	margin-top: 5%;
	cursor:pointer;
	font-weight:bolder
}
a{
    text-decoration: none;
}
.social .facebook{
    color:blue;
    margin-right: 50px;
}
.social .instagram {
    color: #fd2dec;
}
.social img{
    width: 35%;
}
.social a img{
    width: auto;
}
.bottom-space{
    margin-bottom: 30px;
}

/* Media queries*/
@media only screen and (max-width: 1739px) {
 
    .partner-images img{
        margin:5px 6px;
        justify-content: space-around;
    }
}
@media only screen and (max-width: 1059px) {
 
    .footer-item{
        margin:5px 6px;
        width: 33%;
        justify-content: space-around;
    }
}
@media only screen and (max-width: 869px) {
 
    .partner-images img{
        width: 50%;
        margin:5px 5px;
    }

	footer{
        text-align: center;
        flex-wrap: wrap;
	}
    .footer-item{
        width: 100%;
        padding: 0;
    }
    .social img{
        width: 40%;
    }
}

* {

    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    
    /* */
  }
  h1,
  h2,
  h3,
  h4,
  h5{
    font-family: 'Montserrat', sans-serif; 
  }

  .home,
  .services,
  .products,
  .sign-up {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .services {
    /* background-image: url('/images/alcro-beckers.jpg'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .products {
    /* background-image: url('/images/alcro-beckers.jpg'); */
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .sign-up {
    /* background-image: url('/images/alcro-beckers.jpg'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  

.navigeringsbar {
    /* background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);  */
    /* background:linear-gradient(90deg, rgb(44, 195, 255) 0%, rgb(44, 195, 255) 100%); */
    background-color: rgb(9, 82, 151);
    height: 87px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navigeringsbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .navigeringsbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: center;
    margin-right: 2rem;
  }
  
  .nav-item {
    margin-top: 25px;
    height: 80px;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid rgb(255, 255, 255);
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 1140px) {
    .navigeringsbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 95%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 1% 0;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
      
    }
  
    .navigeringsbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: -11px;
      /* transform: translate(25%, 50%); */
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      -webkit-transform: translate(-100%, 60%);
              transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }
  
.cards1 {
  padding: 4rem;
  background: #fff;
}

h1 {
  text-align: center;
}
.cards1 p{

  text-align: center;

  padding-top: 25px; 

}
.cards_container1 {
  display: flex;
  flex-flow: column;
  align-items: center;
  /* max-width: 1120px; */
  width: 90%;
  margin: 0 auto;
  
}

.cards_wrapper1 {
  position: relative;
  margin: 50px 0 45px;
}

.cards_items1 {
  margin-bottom: 24px;
}

.cards_item1 {
  display: flex;
  flex: 1 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards_item_link1 {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 20px;
  overflow: hidden;
  text-decoration: none;
}

.cards_item_pic-wrap1 {
  position: relative;
  width: 100%;
  padding-top: 70%;
  overflow: hidden;
}

.fade-img {
  -webkit-animation-name: fade-img;
          animation-name: fade-img;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}


.cards_item_img1 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

/* .cards_item_img1:hover {
  transform: scale(1.1);
} */

.cards_item_info1 {
  padding: 20px 30px 30px;
}

.cards_item_text1 {
  color: #252e48;
  /* font-size: 10px; */
  /* line-height: 20px; */
}

@media only screen and (min-width: 1200px) {
  .content_blog_container1 {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards_items1 {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards_item1 {
    margin-bottom: 2rem;
  }
}

/* .top-banner{
    width: 90%;
    position: relative;
}
.nodj{
    width: 30%;
    display: inline;
    float: left;
}
.nojd img{
    width: 15%;
}
.banner-img{
    float: right;
}

 */

.banner-img img{
    width: 100%;
}
.nojdkund img{
     width: 75%; 
    display: block;
    margin: 0 auto ;
}
.box{
    text-align: left;
    width: 25%;
    
    padding-top: 3% ;
    background-color: rgb(255, 255, 255);
}
.box p{
    text-align: left;
}
 .infoQ{ 
    padding: 1.5%;  
    
}

.mainQ{
    margin-top: 5%;
}
.mainQ p{
    text-align: left;
}
.mainQimg{
    width: 100%;
}
.mainQ .row{
    margin-top: 1.5%;
    margin-bottom: 1.5%;
}
