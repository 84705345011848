.IconContainer:hover .renovIcon{
    animation: fa-spin .3s forwards linear;
    
    cursor: pointer;
    
}
@keyframes fa-spin{
    0%{
        -webkit-transform:rotate(0deg);
        transform:rotate(0deg)}
        100%{
            -webkit-transform:rotate(30deg);
            transform:rotate(30deg)
        }
    }
.IconContainer{
    text-align: center;
}
.listGroup{
    min-height: 20em;
}
 .bg-bronze{

    background:linear-gradient(90deg, #804a00, #f6b068, #ba7b36, #d7954f )!important;
}
.bg-silver{

    background:linear-gradient(90deg, #a2a2a2, #fffff1, #c0c0c0, #d7d7d7d8 )!important;

} 
.bg-gold{
    background:linear-gradient(90deg, #AE8625, #F7EF8A, #D2AC47, #EDC967 )!important;

}

.btn-gradient{
    color: azure!important;
}