.cards1 {
  padding: 4rem;
  background: #fff;
}

h1 {
  text-align: center;
}
.cards1 p{

  text-align: center;

  padding-top: 25px; 

}
.cards_container1 {
  display: flex;
  flex-flow: column;
  align-items: center;
  /* max-width: 1120px; */
  width: 90%;
  margin: 0 auto;
  
}

.cards_wrapper1 {
  position: relative;
  margin: 50px 0 45px;
}

.cards_items1 {
  margin-bottom: 24px;
}

.cards_item1 {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards_item_link1 {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 20px;
  overflow: hidden;
  text-decoration: none;
}

.cards_item_pic-wrap1 {
  position: relative;
  width: 100%;
  padding-top: 70%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}


.cards_item_img1 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

/* .cards_item_img1:hover {
  transform: scale(1.1);
} */

.cards_item_info1 {
  padding: 20px 30px 30px;
}

.cards_item_text1 {
  color: #252e48;
  /* font-size: 10px; */
  /* line-height: 20px; */
}

@media only screen and (min-width: 1200px) {
  .content_blog_container1 {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards_items1 {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards_item1 {
    margin-bottom: 2rem;
  }
}
