@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto&display=swap');
/* #container{
    width: 100%;
    margin: 0 auto;
} */
/* Partners*/
#partner-banner{
    margin: 0 auto;
    /* background:linear-gradient(90deg, rgb(0, 174, 239) 0%, rgb(0, 174, 239) 100%);  */
    background-color: rgb(9, 82, 151);
    padding: 2%;
    text-align: center;
}
#partner-banner h2{
    text-align: left;
    color: #fff;
    
}
.partner-images{
    display: flex; 
    flex-wrap: wrap; 
    justify-content: space-around; 

    
}
.partner-images img{
height: 70px;
    width: 8%;
margin-left: 65px;
}


/* Footer */
footer{
    padding: 5%;
    display: flex;
    justify-content:center;
    align-items: center;
    flex-wrap: wrap;
}
.footer-item{
    width: 33%;
    padding-left:4% ;
}
footer ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
footer ul span{
    color: rgb(179, 57, 57);
}
.second {
    width: 12%;
}
form{
    border: #302f2f 1px solid;
    padding: 5%;
    box-shadow: #363636 3px 5px;
    
}
form input, form textarea{
	width:100%;
	margin-bottom:5%;
}
form input[type=submit]{
    box-shadow: #363636 5px 5px;
	background-color:#34C759;
	color:white;
	border-radius:5px;
	padding: 10px;
	margin-top: 5%;
	cursor:pointer;
	font-weight:bolder
}
a{
    text-decoration: none;
}
.social .facebook{
    color:blue;
    margin-right: 50px;
}
.social .instagram {
    color: #fd2dec;
}
.social img{
    width: 35%;
}
.social a img{
    width: auto;
}
.bottom-space{
    margin-bottom: 30px;
}

/* Media queries*/
@media only screen and (max-width: 1739px) {
 
    .partner-images img{
        margin:5px 6px;
        justify-content: space-around;
    }
}
@media only screen and (max-width: 1059px) {
 
    .footer-item{
        margin:5px 6px;
        width: 33%;
        justify-content: space-around;
    }
}
@media only screen and (max-width: 869px) {
 
    .partner-images img{
        width: 50%;
        margin:5px 5px;
    }

	footer{
        text-align: center;
        flex-wrap: wrap;
	}
    .footer-item{
        width: 100%;
        padding: 0;
    }
    .social img{
        width: 40%;
    }
}

* {

    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    
    /* */
  }
  h1,
  h2,
  h3,
  h4,
  h5{
    font-family: 'Montserrat', sans-serif; 
  }

  .home,
  .services,
  .products,
  .sign-up {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .services {
    /* background-image: url('/images/alcro-beckers.jpg'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .products {
    /* background-image: url('/images/alcro-beckers.jpg'); */
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .sign-up {
    /* background-image: url('/images/alcro-beckers.jpg'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
