/* .top-banner{
    width: 90%;
    position: relative;
}
.nodj{
    width: 30%;
    display: inline;
    float: left;
}
.nojd img{
    width: 15%;
}
.banner-img{
    float: right;
}

 */

.banner-img img{
    width: 100%;
}
.nojdkund img{
     width: 75%; 
    display: block;
    margin: 0 auto ;
}
.box{
    text-align: left;
    width: 25%;
    
    padding-top: 3% ;
    background-color: rgb(255, 255, 255);
}
.box p{
    text-align: left;
}
 .infoQ{ 
    padding: 1.5%;  
    
}

.mainQ{
    margin-top: 5%;
}
.mainQ p{
    text-align: left;
}
.mainQimg{
    width: 100%;
}
.mainQ .row{
    margin-top: 1.5%;
    margin-bottom: 1.5%;
}